<template>
  <div>
    <header-banner
        banner-img="https://static.moegoat.com/images/banner/banner-4.png"
        title="帮助中心"
        sub-title="常见问题解答"
        class="SecondaryNav"
    />

    <v-container class="my-2 pr-3">
      <v-expansion-panels style="max-width: 52rem" class="mx-auto">
        <v-expansion-panel>
          <v-expansion-panel-header>
            1. IOS苹果下载后解压提示"无法用提供的密码解压缩该归档"
          </v-expansion-panel-header>
          <v-expansion-panel-content class="body-1">
            <div class="mb-5">
              ⚠️注意：IOS下载后的资源请在第三方解压App内打开，直接在浏览器直接打开会导致[密码错误] -> "无法用提供的密码解压缩该归档"而解压失败。
            </div>

            <div class="mb-1">
              📱  ios上推荐的解压App
              下载后将下载的资源导入解压App内打开即可解压
              [App Store选择任意下载即可 🔽]
            </div>

            <v-alert
                border="left"
                colored-border
                color="primary"
                elevation="2"
                class="mt-4"
            >
              <ol>
                <li>解压专家</li>
                <li>iZip</li>
                <li>解压大师</li>
              </ol>
            </v-alert>

            <v-img contain height="250px" src="http://wimg.973.com/u/c/1203112237185.jpg"></v-img>
            <div class="mt-3">下载后使用任一解压软件打开从网站下载的资源即可</div>


          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-for="q in question_list" :key="q.id">
          <v-expansion-panel-header>
            {{q.id + '. ' + q.q}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{q.a}}
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-alert
            dark
            class="mt-5"
            icon="mdi-information"
            border="left"
            prominent
            color="#FFA62F"
        >
          VIP如何升级巴士会SVIP？
          <div>巴士会SVIP的有效时间独立计算，开通将同时增加VIP时间，不与VIP合并，可在 [个人资料] 查看，无须开通相同等级的巴士会SVIP也可享受巴士会权益。</div>
        </v-alert>

        <v-btn block class="mt-1" color="primary" @click="contact_service" height="40">
          <v-icon class="mr-1">mdi-face-agent</v-icon> 联系在线客服
        </v-btn>
      </v-expansion-panels>


    </v-container>
  </div>
</template>

<script>
import HeaderBanner from "@/components/HeaderBanner.vue";

export default {
  components: {HeaderBanner},
  data(){
    return{
      question_list:[
        {
          id:2,
          q:'解压密码错误',
          a:'解压密码以下载页的为准，每个资源的密码不一定相同，若密码错误，可进入个人中心-[下载记录] 查看对应解压密码。'
        },
        {
          id:3,
          q:'下载地址显示正在上传中',
          a:'资源上传到服务器由于网络延迟原因，可能会延时，耐心等待一段时间后即可下载。'
        },
        {
          id:4,
          q:'下载后显示压缩包已损坏',
          a:'服务器中的资源都经过MD5完整性校验核对后上传，不会存在损坏文件，在您的下载过程中，由于网络不稳定或中断，可能会导致接收文件不完整，建议重新下载。'
        },
        {
          id:5,
          q:'已付款会员未开通成功',
          a:'很抱歉，可能是支付服务器临时出现异常，请勿取消付款后未开通的订单，并点击右下角的在线客服，发送 [订单校验码] + [交易订单号] + [支付详情截图]，将在审核后开通，也可提交至邮箱 loibus.service@gmail.com 获取帮助。'
        },
        {
          id:6,
          q:'页面/图片显示异常或卡顿，无法正常加载',
          a:'推荐使用最新chrome浏览器以获得完整体验，IOS用户也可以使用safari浏览器。'
        },
        {
          id:7,
          q:'会员是否支持补差价升级？',
          a:'很抱歉，目前不支持差价升级会员。仅支持终身VIP补差价升级终身巴士会SVIP（30天内）'
        },
        {
          id:8,
          q:'我想求资源',
          a:'发送您想要的资源的[人物名称]+[作品名称]+[主页链接]到在线客服，资源将在获取后第一时间发布。'
        },
        {
          id:9,
          q:'我想投稿资源',
          a:'仅收购无水印未流出的一手资源，发送您要投稿资源的[人物名称]+[作品名称]+[主页链接]+[您的报价]+[联系方式]到在线客服，资源专员将在稍后与您取得联系。'
        },
        {
          id:10,
          q:'商务广告合作',
          a:'发送您的[产品名称]+[产品链接]+[联系方式]到在线客服，商务专员将在稍后联系您。'
        },
        {
          id:11,
          q:'无法联系到客服',
          a:'若由于客服繁忙未及时回复，可提交至邮箱 loibus.service@gmail.com 获取帮助。'
        },
        {
          id:12,
          q:'发布页地址',
          a:'loix.cc'
        },
        {
          id:13,
          q:'巴士会SVIP问题',
          a:`
            开通后会同时增加VIP有效时间与巴士会有效时间，购买后优先使用巴士会SVIP权益，未使用的VIP权益将在巴士会SVIP到期后继续生效；
            如果您是新用户，可直接开通巴士会SVIP享受尊享权益;
            如果您是老用户，开通巴士会SVIP将延长您的VIP时间，同时享受巴士会权益；
            终身VIP可30天内补差价升级至终身巴士会SVIP，或开通任意时长巴士会，也可在有效时间内享受巴士会权益。
          `
        },
      ]
    }
  },
  methods:{
    contact_service(){
      window.open('https://chatting.page/t9ac8zwwowzq4q2todl6ntrw6m7wpcsu');
    }
  }
}
</script>

<style scoped>

</style>